import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const KreditzinsenEntwicklung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Kreditzinsen – Aktuelle Situation und Entwicklung" showCalc={false} />
            <Article>
                <h2>Kreditzinsen – die aktuelle Situation in Österreich</h2>
                <p>
                    Nicht zuletzt wegen der Corona-Pandemie befinden sich die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    auch hierzulande auf einem beinahe historisch niedrigen Niveau. Erklären lässt sich das mit der
                    Geldpolitik der Europäischen Zentralbank (EZB), von der auch die Entwicklungen auf dem
                    österreichischen Finanzmarkt abhängig sind. Das in der Eurozone aufgrund der epidemiologischen
                    Einschränkungsmaßnahmen über fünf Quartale hinweg stetig gesunkene BIP hat u.a. zur Folge, dass
                    insbesondere Immobilienkredite derzeit ziemlich günstig zu erhalten sind.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wie könnten sich die Zinsen in Zukunft entwickeln?</h2>
                <p>
                    Die höhere Inflation könnte jedoch dazu führen, dass Banken in Zukunft von Kreditinteressent:innen
                    einen höheren Anteil an Eigenmittel bei der Finanzierung einer Immobilie verlangen. Analog dazu
                    könnten zudem die Risikoaufschläge bei Vertragsabschlüssen steigen, was sich langfristig in einer
                    Verteuerung der Konditionen von Neukrediten niederschlagen würde. Bei diesen Entwicklungen handelt
                    es sich jedoch lediglich um mögliche Szenarien, auf die im Augenblick nur äußerst kleinteilige
                    Phänomene auf dem Finanzmarkt schließen lassen. Ausschließen lässt sich das Eintreten dieser
                    Szenarien allerdings nicht und sollte daher bei der Kreditaufnahme möglichst mitbedacht werden.
                </p>
                <hr />

                <h2>Wir wirken sich die aktuellen Zinsen auf den Immobilienmarkt aus?</h2>
                <p>
                    Momentan sind die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    also ziemlich niedrig. Seit 2015 haben wir sogar mit Negativzinsen zu tun, die seit 2019 sogar die
                    sehr langfristigen Zinsen am Markt für Zinstauschgeschäfte (EUR-Swapsätze) erreicht haben. Für
                    Kretinehmer:innen sind das sehr gute Nachrichten. Aber wir dürfen uns nicht zu früh freuen, denn wir
                    wissen noch nciht, wie sich das genau auf den Immobilienmarkt auswirken wird. (...) → “Allerdings
                    stellen{" "}
                    <strong>
                        Warenknappheiten, Lieferengpässe, möglicherweise überzogene Lohnrunden (Lohn-Preis-Spirale)
                    </strong>
                    Risiken dar, die vor allem schnell am langen Ende zu Zinsanstiegen führen können.”
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"kreditzinsenEntwicklung"}></BreadcrumbList>
            <ArticleStructuredData
                page={"kreditzinsenEntwicklung"}
                heading={"Kreditzinsen – Aktuelle Situation und Entwicklung"}
            />
        </Layout>
    );
};

export default KreditzinsenEntwicklung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kreditzinsen-entwicklung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
